import React, { Component, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MainLogo from "../MainLogo.png";
import NavBar from "./NavBar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Modal from "@material-ui/core/Modal";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  Link,
} from "react-router-dom";

import { Beforeunload } from "react-beforeunload";
import shortid from "shortid";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import newProduct from "../newProduct.png";
import milkTeaPouring2 from "../MilkTeaPouring2.gif";
import milkTeaPouring4 from "../MilkTeaPouring4.gif";
import milkTeaPouring1 from "../MilkTeaPouring1.jpg";
import milkTeaPouring3 from "../MilkTeaPouring3.jpg";
import milkTeaPouring5 from "../MilkTeaPouring5.jpg";

import Sheet from "react-modal-sheet";

import { withRouter } from "react-router-dom";

import { isMobileOnly, isIE, isEdge, isSafari } from "react-device-detect";
import { Prompt } from "react-router";

import Select from "react-select";

import { Image, Col, Row, Container } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!

import moment from "moment";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCartOutlined";
import LocalShippingIcon from "@material-ui/icons/LocalShippingOutlined";
import LocalMallIcon from "@material-ui/icons/LocalMallOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faTruckMoving,
  faCheck,
  faWarehouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ReactStars from "react-star-ratings";
import Slider from "react-slick";

import Footer from "./Footer";

import chatIcon from "../coffeeIcon.jpg";
import plainSconeIcon from "../PlainScone.png";
import compoteAndCreamDetail from "../compoteAndCreamDetail.jpg";
import roundIcon from "../roundIcon.png";

import * as firebase from "firebase";

import { loadTossPayments } from "@tosspayments/sdk";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class MilkTeaBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      milkTeaBase: null,

      items: null,
      users: null,

      reviews: [],
      showIndex: null,
      imageIndex: 0,

      selectedItems: {},
      noItemsSnack: false,

      selectedItemsTotalAmount: 0,
      selectedItemsTotalPrice: 0,

      noMoreItemsSnack: false,

      openMilkTeaBaseItems: false,
      getItemInCartSnack: false,
      waitMessage: "잠시만 기다려주세요...",
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ loadingStart: true });
    }, 100);
    setTimeout(() => {
      this.setState({ waitMessage: "디저트들을 불러오고 있습니다..." });
    }, 5000);

    setTimeout(() => {
      this.setState({ waitMessage: "거의 다 됐습니다!!!" });
    }, 10000);
  }

  componentDidMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo();
    }
    firebase
      .database()
      .ref(`users`)
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          let users = snapshot.val();
          this.setState({ users: users });
        }
      });

    if (Object.values(this.props.itemsObj).length !== 0) {
      this.setState({ items: this.props.itemsObj });
      let milkTeaBaseObj = this.props.itemsObj;
      delete milkTeaBaseObj["tJjHJ-FhL"];
      delete milkTeaBaseObj["xCQ9yJMcJ"];
      let milkTeaBaseList = Object.values(milkTeaBaseObj);
      milkTeaBaseList = milkTeaBaseList.filter(
        (milkTeaBase) => milkTeaBase.category === "milkTeaBase"
      );
      let milkTeaBasekeys = milkTeaBaseList.map((a) => a.id);
      let milkTeaBase = {};
      milkTeaBasekeys.map((key, index) => {
        milkTeaBase[key] = milkTeaBaseList[index];

        milkTeaBaseList.map((milkTeaBase, i) => {
          if (milkTeaBase.open) {
            this.setState({ openMilkTeaBaseItems: true });
          }
        });

        if (milkTeaBasekeys.length === index + 1) {
          this.setState({ milkTeaBase: milkTeaBase });
          let reviews = [];
          let bestReviews = [];
          let imageReviews = [];
          let noImageReviews = [];
          Object.values(milkTeaBase).map((item, innerIndex) => {
            if (item.reviews) {
              reviews = reviews.concat(Object.values(item.reviews));
            }
            if (Object.values(milkTeaBase).length === innerIndex + 1) {
              reviews.sort((a, b) => {
                return b.createdAt - a.createdAt;
              });
              bestReviews = reviews.filter((reviews) => reviews.best);
              imageReviews = reviews.filter((reviews) => reviews.images);
              imageReviews = imageReviews.filter(
                (imageReviews) => !imageReviews.best
              );
              imageReviews.sort((a, b) => {
                return b.rating - a.rating;
              });
              imageReviews.sort((a, b) => {
                return b.review.length - a.review.length;
              });
              noImageReviews = reviews.filter((reviews) => !reviews.images);
              noImageReviews = noImageReviews.filter(
                (noImageReviews) => !noImageReviews.best
              );
              noImageReviews.sort((a, b) => {
                return b.rating - a.rating;
              });
              noImageReviews.sort((a, b) => {
                return b.review.length - a.review.length;
              });
              reviews = bestReviews.concat(imageReviews);
              reviews = reviews.concat(noImageReviews);

              this.setState({ reviews: reviews });
            }
          });
        }
      });
    } else {
      firebase
        .database()
        .ref(`items`)
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            this.setState({ items: snapshot.val() });
            if (this.props.onSetItemsObj) {
              this.props.onSetItemsObj(snapshot.val());
            }
            let milkTeaBaseObj = snapshot.val();
            delete milkTeaBaseObj["tJjHJ-FhL"];
            delete milkTeaBaseObj["xCQ9yJMcJ"];
            let milkTeaBaseList = Object.values(milkTeaBaseObj);
            milkTeaBaseList = milkTeaBaseList.filter(
              (milkTeaBase) => milkTeaBase.category === "milkTeaBase"
            );
            let milkTeaBasekeys = milkTeaBaseList.map((a) => a.id);
            let milkTeaBase = {};
            milkTeaBasekeys.map((key, index) => {
              milkTeaBase[key] = milkTeaBaseList[index];

              milkTeaBaseList.map((milkTeaBase, i) => {
                if (milkTeaBase.open) {
                  this.setState({ openMilkTeaBaseItems: true });
                }
              });

              if (milkTeaBasekeys.length === index + 1) {
                this.setState({ milkTeaBase: milkTeaBase });
                let reviews = [];
                let bestReviews = [];
                let imageReviews = [];
                let noImageReviews = [];
                Object.values(milkTeaBase).map((item, innerIndex) => {
                  if (item.reviews) {
                    reviews = reviews.concat(Object.values(item.reviews));
                  }
                  if (Object.values(milkTeaBase).length === innerIndex + 1) {
                    reviews.sort((a, b) => {
                      return b.createdAt - a.createdAt;
                    });
                    bestReviews = reviews.filter((reviews) => reviews.best);
                    imageReviews = reviews.filter((reviews) => reviews.images);
                    imageReviews = imageReviews.filter(
                      (imageReviews) => !imageReviews.best
                    );
                    imageReviews.sort((a, b) => {
                      return b.rating - a.rating;
                    });
                    imageReviews.sort((a, b) => {
                      return b.review.length - a.review.length;
                    });
                    noImageReviews = reviews.filter(
                      (reviews) => !reviews.images
                    );
                    noImageReviews = noImageReviews.filter(
                      (noImageReviews) => !noImageReviews.best
                    );
                    noImageReviews.sort((a, b) => {
                      return b.rating - a.rating;
                    });
                    noImageReviews.sort((a, b) => {
                      return b.review.length - a.review.length;
                    });
                    reviews = bestReviews.concat(imageReviews);
                    reviews = reviews.concat(noImageReviews);

                    this.setState({ reviews: reviews });
                  }
                });
              }
            });
          } else {
          }
        });
    }
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangeCurrencyFormat(value) {
    return value
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  onDateToTimeStamp(date) {
    let changedDate =
      date.substring(0, 4) +
      "-" +
      date.substring(4, 6) +
      "-" +
      date.substring(6, 8);
    let timestamp = new Date(changedDate).getTime();
    return timestamp;
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString();
    return (
      stringNumber.substring(0, 4) +
      "년 " +
      stringNumber.substring(4, 6) +
      "월 " +
      stringNumber.substring(6, 8) +
      "일"
    );
  }

  onGetLastWeek() {
    let today = new Date();
    let lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    return lastWeek;
  }

  onGetCurrentDateWithDay(day) {
    let today = new Date();
    let dd = day.toString().padStart(2, "0");
    let mm = (today.getMonth() + 1).toString().padStart(2, "0");
    let yyyy = today.getFullYear();
    let result = new Date(`${yyyy}/${mm}/${day}`);
    return result;
  }

  onChangePhoneNumberFormat(phone) {
    let value = "";
    if (phone) {
      if (phone.substr(0, 3) === "010") {
        if (phone.substr(7, 4)) {
          value =
            phone.substr(0, 3) +
            "-" +
            phone.substr(3, 4) +
            "-" +
            phone.substr(7, 4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4);
        } else {
          value = phone.substr(0, 3);
        }
      } else {
        if (phone.substr(6, 4)) {
          value =
            phone.substr(0, 3) +
            "-" +
            phone.substr(3, 3) +
            "-" +
            phone.substr(6, 4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3);
        } else {
          value = phone.substr(0, 3);
        }
      }
    }

    return value;
  }

  onGetWeek(time) {
    let d = time;
    let date = d.getDate();
    let day = d.getDay();

    let weekOfMonth = Math.ceil((date + 6 - day) / 7);
    return weekOfMonth;
  }

  onGetMonth(time) {
    return time.getMonth();
  }

  onGetLastDayOfMonth() {
    const lastDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate();
    return lastDay;
  }

  onGetDay(date) {
    let day = "";
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = "일";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1
    ) {
      day = "월";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2
    ) {
      day = "화";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3
    ) {
      day = "수";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4
    ) {
      day = "목";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5
    ) {
      day = "금";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6
    ) {
      day = "토";
    }
    return day;
  }

  sortByOrder(array) {
    let returnValue = array;
    let sconeMenu = this.state.sconeMenu;
    returnValue.sort((a, b) => {
      return sconeMenu[a.id].order - sconeMenu[b.id].order;
    });
    return returnValue;
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate);
    return Number(Math.floor(totalPrice / 10) * 10);
  }

  onCountSelectedItems(selectedItems) {
    if (Object.values(selectedItems).length !== 0) {
      let selectedItemsTotalAmount = 0;
      let selectedItemsTotalPrice = 0;
      Object.values(selectedItems).map((item, index) => {
        selectedItemsTotalAmount = selectedItemsTotalAmount + item.amount;
        if (item.discountedPrice) {
          selectedItemsTotalPrice =
            selectedItemsTotalPrice + item.discountedPrice * item.amount;
        } else {
          selectedItemsTotalPrice =
            selectedItemsTotalPrice + item.price * item.amount;
        }

        if (Object.values(selectedItems).length === index + 1) {
          this.setState({
            selectedItemsTotalPrice: selectedItemsTotalPrice,
            selectedItemsTotalAmount: selectedItemsTotalAmount,
          });
        }
      });
    } else {
      this.setState({
        selectedItemsTotalAmount: 0,
        selectedItemsTotalPrice: 0,
      });
    }
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear();
    let months = new Date(time).getMonth() + 1;
    let days = new Date(time).getDate();
    let hours = new Date(time).getHours();
    let minutes = new Date(time).getMinutes();
    let seconds = new Date(time).getSeconds();

    let morningOrAfternoon = "오전";

    if (Number(hours) > 11) {
      morningOrAfternoon = "오후";
    }

    if (Number(hours) > 12) {
      hours = hours - 12;
    }

    if (hours === 0) {
      hours = 12;
    }

    if (Number(minutes) < 10) {
      minutes = "0" + minutes;
    }

    return `${years}.${months}.${days}`;
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
      }),
    };

    if (false) {
      return (
        <div className="center-div direction-column-div">
          <Image style={{ width: 250 }} src={newProduct} />
          <span
            className="text-center"
            style={{ fontSize: 16, fontWeight: "bold", marginTop: 15 }}
          >
            새로운 상품을 준비 중입니다.
          </span>
        </div>
      );
    }

    if (this.state.isLoading || !this.state.milkTeaBase) {
      return (
        <div
          className={
            this.state.loadingStart
              ? "center-div direction-column-div loading-animation1"
              : "center-div direction-column-div loading-animation2"
          }
        >
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Image src={roundIcon} style={{ width: 60, height: 60 }} fluid />
            <span
              className="text-center"
              style={{ fontSize: 11, marginTop: 5 }}
            >
              {this.state.waitMessage}
            </span>
          </Grid>
        </div>
      );
    }

    let settings = {
      className: "",
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: false,
    };

    return (
      <Grid className="main-view auto-width-100 no-selection">
        <div className="max-height-100">
          <Image
            className="auto-width-100 max-height-100"
            src={milkTeaPouring1}
            fluid
          />
        </div>
        <div className="max-height-100">
          <Image
            className="auto-width-100 max-height-100"
            src={milkTeaPouring2}
            fluid
          />
        </div>
        <div className="max-height-100">
          <Image
            className="auto-width-100 max-height-100"
            src={milkTeaPouring3}
            fluid
          />
        </div>
        <div className="max-height-100">
          <Image
            className="auto-width-100 max-height-100"
            src={milkTeaPouring4}
            fluid
          />
        </div>
        <div className="max-height-100">
          <Image
            className="auto-width-100 max-height-100"
            src={milkTeaPouring5}
            fluid
          />
        </div>

        {this.state.reviews.length !== 0 && (
          <div>
            <Divider className="auto-width-100" style={{ marginBottom: 20 }} />
            <span style={{ fontWeight: "bold", marginLeft: 25 }}>
              상품 리뷰
            </span>
            <Divider className="auto-width-100" style={{ marginTop: 20 }} />
          </div>
        )}
        {this.state.reviews.length !== 0 && (
          <Grid style={{ marginBottom: 20, marginTop: 20 }}>
            <div>
              {this.state.reviews.map((review, index) => {
                return (
                  <Grid
                    style={{ marginBottom: 10 }}
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    className="auto-width-100 relative-position"
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (this.state.showIndex === index) {
                          this.setState({ showIndex: null, imageIndex: 0 });
                        } else {
                          this.setState({ showIndex: index, imageIndex: 0 });
                        }
                      }}
                      className="auto-width-90"
                    >
                      <div className="direction-row-div align-center">
                        <ReactStars
                          numberOfStars={5}
                          rating={review.rating}
                          edit={false}
                          starRatedColor={"#006042"}
                          starHoverColor={"#006042"}
                          starEmptyColor={"#bababa"}
                          starDimension={"15px"}
                          starSpacing={"0.5px"}
                        />
                        {this.state.users && (
                          <span
                            style={{
                              fontSize: 13,
                              marginLeft: 10,
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.users[review.uid].username.charAt(0) +
                              "**"}
                          </span>
                        )}
                        <span
                          style={{
                            fontSize: 13,
                            marginLeft: 10,
                            color: "gray",
                          }}
                        >
                          {this.onGetTimeDetail(review.createdAt)}
                        </span>
                        {review.best && (
                          <span
                            className="small-red-box"
                            style={{
                              fontSize: 11,
                              marginLeft: 10,
                              color: "white",
                              marginTop: 2,
                            }}
                          >
                            베스트 리뷰
                          </span>
                        )}
                      </div>
                      <div className="direction-row-div">
                        {this.state.showIndex === index ? (
                          <div className="auto-width-90">
                            <span className="no-line" style={{ fontSize: 13 }}>
                              {review.review}
                            </span>
                          </div>
                        ) : (
                          <div style={{ height: 60 }} className="auto-width-70">
                            <span className="two-line" style={{ fontSize: 13 }}>
                              {review.review}
                            </span>
                          </div>
                        )}
                        {this.state.showIndex !== index && review.images && (
                          <div
                            className="auto-width-10"
                            style={{ position: "absolute", top: 20, right: 20 }}
                          >
                            <Image
                              className="image-object-fit-50"
                              style={{ borderRadius: 5 }}
                              src={review.images[0]}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="direction-row-div align-center">
                      {this.state.showIndex === index && (
                        <div style={{ width: 20 }}>
                          {this.state.imageIndex !== 0 && (
                            <FontAwesomeIcon
                              onClick={() => {
                                this.previous();
                              }}
                              style={{
                                color: "black",
                                fontSize: 20,
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              icon={faChevronLeft}
                            />
                          )}
                        </div>
                      )}

                      {this.state.showIndex === index && review.images && (
                        <div style={{ width: 280, marginTop: 50 }}>
                          <Slider
                            ref={(c) => (this.slider = c)}
                            beforeChange={(oldIndex, newIndex) => {
                              this.setState({ imageIndex: newIndex });
                            }}
                            {...settings}
                          >
                            {review.images.map((image, index) => {
                              return <Image src={image} />;
                            })}
                          </Slider>
                        </div>
                      )}
                      {this.state.showIndex === index && review.images && (
                        <div style={{ width: 20 }}>
                          {this.state.imageIndex !==
                            review.images.length - 1 && (
                            <FontAwesomeIcon
                              onClick={() => {
                                this.next();
                              }}
                              style={{
                                color: "black",
                                fontSize: 20,
                                marginLeft: 10,
                                cursor: "pointer",
                              }}
                              icon={faChevronRight}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    {this.state.showIndex === null ? (
                      <Divider className="auto-width-100" />
                    ) : this.state.showIndex === index ? (
                      <Divider
                        className="auto-width-100"
                        style={{ marginTop: 40 }}
                      />
                    ) : (
                      <Divider className="auto-width-100" />
                    )}
                  </Grid>
                );
              })}
            </div>
          </Grid>
        )}
        {!this.state.openMilkTeaBaseItems && (
          <div className="none-underline-text btn-bottom-notice-sticky no-selection direction-column-div">
            <span
              style={{
                color: "white",
                fontSize: 11,
                margin: 15,
                marginBottom: 3,
              }}
            >
              매주 금요일, 토요일 오전 10시 ~ 밤 12시 오픈
            </span>
            <span style={{ color: "white", fontSize: 11, marginBottom: 15 }}>
              (월요일 일괄 발송)
            </span>
          </div>
        )}

        <div
          className="none-underline-text btn-bottom-big-sticky no-selection direction-column-div"
          onClick={() => {
            if (this.state.userInfo) {
              this.setState({ isOpenSheet: true });
            } else {
              this.props.history.push("/login?redirect=/home/milk_tea_base");
              setTimeout(() => {
                this.props.routeChanges();
              }, 10);
            }
          }}
        >
          <span style={{ color: "white", fontSize: 14, margin: 15 }}>
            상품 구매하기
          </span>
        </div>
        <Sheet
          disableDrag={true}
          className="sheet-style disable-double-tabs"
          isOpen={this.state.isOpenSheet}
          onClose={() => this.setState({ isOpenSheet: false })}
        >
          <Sheet.Container className="sheet-style">
            <Sheet.Header className="sheet-header">
              <div className="text-center">
                <span style={{ fontWeight: "bold", fontSize: 18 }}>
                  상품구매
                </span>
              </div>
              <div
                item
                className="no-selection back-button"
                style={{ position: "absolute", right: 20, top: 20 }}
                onClick={() => {
                  this.setState({
                    isOpenSheet: false,
                  });
                }}
              >
                <CloseIcon style={{ color: "#5e5e5e", fontSize: 26 }} />
              </div>
            </Sheet.Header>
            <Sheet.Content className="auto-width-100 relative-position">
              <div style={{ paddingBottom: 120 }}>
                {Object.values(this.state.milkTeaBase).map((item, index) => {
                  return (
                    <Grid
                      container
                      style={{
                        marginTop: 30,
                        marginBottom: 30,
                        position: "relative",
                      }}
                      direction="row"
                    >
                      <Grid
                        item
                        className="direction-row-div"
                        justify="center"
                        xs={4}
                      >
                        <Image
                          style={{ width: 100, height: 100 }}
                          src={item.image}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <p
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {item.name}
                        </p>
                        <p
                          className="break-word"
                          style={{
                            fontSize: 12,
                            margin: 0,
                            padding: 0,
                            marginTop: 5,
                            color: "gray",
                          }}
                        >
                          {item.explanation}
                        </p>
                        {item.discountedPrice ? (
                          <div className="direction-column-div">
                            <div className="direction-row-div">
                              <p
                                className="strike"
                                style={{
                                  fontSize: 13,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  marginRight: 5,
                                }}
                              >
                                {this.onChangeCurrencyFormat(item.price)}원
                              </p>
                              <p
                                style={{
                                  fontSize: 13,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                }}
                              >
                                {this.onChangeCurrencyFormat(
                                  item.discountedPrice
                                )}
                                원
                              </p>
                            </div>
                            <p
                              style={{
                                fontSize: 13,
                                margin: 0,
                                padding: 0,
                                marginTop: 3,
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {Math.round(
                                (1 - item.discountedPrice / item.price) * 100
                              )}
                              % 할인
                            </p>
                          </div>
                        ) : (
                          <p
                            style={{
                              fontSize: 13,
                              margin: 0,
                              padding: 0,
                              marginTop: 5,
                            }}
                          >
                            {this.onChangeCurrencyFormat(item.price)}원
                          </p>
                        )}
                      </Grid>
                      <Grid
                        item
                        className="direction-row-div no-selection"
                        style={{ position: "absolute", right: 20, bottom: 0 }}
                      >
                        {this.state.selectedItems[item.id] ? (
                          item.discountedPrice ? (
                            <p
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                margin: 0,
                                padding: 0,
                                marginTop: 16,
                                marginRight: 8,
                              }}
                            >
                              {this.onChangeCurrencyFormat(
                                this.state.selectedItems[item.id].amount *
                                  item.discountedPrice
                              )}
                              원
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                margin: 0,
                                padding: 0,
                                marginTop: 16,
                                marginRight: 8,
                              }}
                            >
                              {this.onChangeCurrencyFormat(
                                this.state.selectedItems[item.id].amount *
                                  item.price
                              )}
                              원
                            </p>
                          )
                        ) : (
                          <p
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              margin: 0,
                              padding: 0,
                              marginTop: 16,
                              marginRight: 8,
                            }}
                          ></p>
                        )}
                        {item.open === false || item.amount < 1 ? (
                          <div className="counter-button-div">
                            <div onClick={() => {}} className="counter-button">
                              <RemoveIcon fontSize={"small"} />
                            </div>
                            <div className="counter-style">
                              <p
                                style={{ fontSize: 10, margin: 0, padding: 0 }}
                              >
                                품절
                              </p>
                            </div>
                            <div onClick={() => {}} className="counter-button">
                              <AddIcon fontSize={"small"} />
                            </div>
                          </div>
                        ) : (
                          <div className="counter-button-div">
                            <div
                              onClick={() => {
                                let selectedItems = this.state.selectedItems;
                                let milkTeaBase = this.state.milkTeaBase;
                                let totalAmount = this.state.totalAmount;
                                let totalPrice = this.state.totalPrice;

                                if (selectedItems[item.id]) {
                                  if (
                                    totalAmount &&
                                    selectedItems[item.id].amount !== 0
                                  ) {
                                    totalAmount = totalAmount - 1;
                                    if (item.discountedPrice) {
                                      totalPrice =
                                        totalPrice - item.discountedPrice;
                                    } else {
                                      totalPrice = totalPrice - item.price;
                                    }
                                  }
                                  if (item.discountedPrice) {
                                    selectedItems[item.id] = {
                                      itemId: item.id,
                                      name: milkTeaBase[item.id].name,
                                      image: milkTeaBase[item.id].image,
                                      amount: selectedItems[item.id].amount - 1,
                                      price: item.discountedPrice,
                                    };
                                  } else {
                                    selectedItems[item.id] = {
                                      itemId: item.id,
                                      name: milkTeaBase[item.id].name,
                                      image: milkTeaBase[item.id].image,
                                      amount: selectedItems[item.id].amount - 1,
                                      price: item.price,
                                    };
                                  }

                                  if (selectedItems[item.id].amount === 0) {
                                    delete selectedItems[item.id];
                                  }
                                }

                                this.setState({
                                  totalAmount: totalAmount,
                                  totalPrice: totalPrice,
                                  selectedItems: selectedItems,
                                });
                                this.onCountSelectedItems(selectedItems);
                              }}
                              className="counter-button"
                            >
                              <RemoveIcon fontSize={"small"} />
                            </div>
                            <div className="counter-style">
                              <p
                                style={{ fontSize: 12, margin: 0, padding: 0 }}
                              >
                                {this.state.selectedItems[item.id]
                                  ? this.state.selectedItems[item.id].amount
                                  : 0}
                              </p>
                            </div>
                            <div
                              onClick={() => {
                                let selectedItems = this.state.selectedItems;
                                let totalAmount = this.state.totalAmount;
                                let totalPrice = this.state.totalPrice;
                                let milkTeaBase = this.state.milkTeaBase;

                                let userInfo = this.state.userInfo;

                                let amountInCart = 0;

                                if (userInfo.cart) {
                                  if (userInfo.cart[item.id]) {
                                    amountInCart =
                                      userInfo.cart[item.id].amount;
                                  }
                                }

                                if (selectedItems[item.id]) {
                                  if (
                                    this.state.items[item.id].amount -
                                      amountInCart >
                                    selectedItems[item.id].amount
                                  ) {
                                    if (item.discountedPrice) {
                                      selectedItems[item.id] = {
                                        itemId: item.id,
                                        name: milkTeaBase[item.id].name,
                                        image: milkTeaBase[item.id].image,
                                        amount:
                                          selectedItems[item.id].amount + 1,
                                        price: item.discountedPrice,
                                      };
                                    } else {
                                      selectedItems[item.id] = {
                                        itemId: item.id,
                                        name: milkTeaBase[item.id].name,
                                        image: milkTeaBase[item.id].image,
                                        amount:
                                          selectedItems[item.id].amount + 1,
                                        price: item.price,
                                      };
                                    }

                                    if (item.discountedPrice) {
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1;
                                        totalPrice =
                                          totalPrice + item.discountedPrice;
                                      } else {
                                        totalAmount = 1;
                                        totalPrice = item.discountedPrice;
                                      }
                                    } else {
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1;
                                        totalPrice = totalPrice + item.price;
                                      } else {
                                        totalAmount = 1;
                                        totalPrice = item.price;
                                      }
                                    }

                                    this.setState({
                                      totalAmount: totalAmount,
                                      totalPrice: totalPrice,
                                      selectedItems: selectedItems,
                                    });
                                    this.onCountSelectedItems(selectedItems);
                                  } else {
                                    alert("재고가 부족합니다!");
                                  }
                                } else {
                                  if (
                                    this.state.items[item.id].amount -
                                      amountInCart >
                                    0
                                  ) {
                                    if (item.discountedPrice) {
                                      selectedItems[item.id] = {
                                        itemId: item.id,
                                        name: milkTeaBase[item.id].name,
                                        image: milkTeaBase[item.id].image,
                                        amount: 1,
                                        price: item.discountedPrice,
                                      };
                                    } else {
                                      selectedItems[item.id] = {
                                        itemId: item.id,
                                        name: milkTeaBase[item.id].name,
                                        image: milkTeaBase[item.id].image,
                                        amount: 1,
                                        price: item.price,
                                      };
                                    }
                                    if (item.discountedPrice) {
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1;
                                        totalPrice =
                                          totalPrice + item.discountedPrice;
                                      } else {
                                        totalAmount = 1;
                                        totalPrice = item.discountedPrice;
                                      }
                                    } else {
                                      if (totalAmount) {
                                        totalAmount = totalAmount + 1;
                                        totalPrice = totalPrice + item.price;
                                      } else {
                                        totalAmount = 1;
                                        totalPrice = item.price;
                                      }
                                    }

                                    this.setState({
                                      totalAmount: totalAmount,
                                      totalPrice: totalPrice,
                                      selectedItems: selectedItems,
                                    });
                                    this.onCountSelectedItems(selectedItems);
                                  } else {
                                    alert("재고가 부족합니다!");
                                  }
                                }
                              }}
                              className="counter-button"
                            >
                              <AddIcon fontSize={"small"} />
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Divider style={{ marginTop: 15 }} />
                <div
                  className="auto-width-100 relative-position"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <span
                    style={{ position: "absolute", left: 30, fontSize: 13 }}
                  >
                    총 수량{" "}
                    <span style={{ color: "#006042", fontWeight: "bold" }}>
                      {this.state.selectedItemsTotalAmount
                        ? this.state.selectedItemsTotalAmount
                        : 0}
                    </span>
                    개
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      right: 20,
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    총 금액{" "}
                    {this.state.selectedItemsTotalPrice
                      ? this.onChangeCurrencyFormat(
                          this.state.selectedItemsTotalPrice
                        )
                      : 0}
                    원
                  </span>
                </div>
              </div>
              <a
                onClick={() => {
                  let cart = {};
                  if (Object.keys(this.state.selectedItems).length) {
                    firebase
                      .database()
                      .ref(`users/${this.state.userInfo.uid}/cart`)
                      .once("value", (snapshot) => {
                        if (snapshot.val()) {
                          cart = snapshot.val();
                          Object.values(this.state.selectedItems).map(
                            (selectedItem, index) => {
                              if (cart[selectedItem.itemId]) {
                                let amount = cart[selectedItem.itemId].amount;
                                amount = amount + selectedItem.amount;
                                cart[selectedItem.itemId].amount = amount;
                              } else {
                                cart[selectedItem.itemId] = selectedItem;
                              }
                              if (
                                Object.values(this.state.selectedItems)
                                  .length ===
                                index + 1
                              ) {
                                firebase
                                  .database()
                                  .ref(`users/${this.state.userInfo.uid}/cart`)
                                  .set(cart);
                              }
                            }
                          );
                        } else {
                          cart = this.state.selectedItems;
                          firebase
                            .database()
                            .ref(`users/${this.state.userInfo.uid}/cart`)
                            .set(cart);
                        }
                        this.onCountSelectedItems({});
                        this.setState({
                          selectedItems: {},
                          isOpenSheet: false,
                          getItemInCartSnack: true,
                        });
                      });
                  } else {
                    alert("상품을 선택해주세요.");
                  }
                }}
                className="btn-very-bottom-big no-selection"
              >
                <p style={{ color: "white", fontSize: 15 }}>장바구니에 담기</p>
              </a>
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
        <Footer />
        <Snackbar
          open={this.state.noMoreItemsSnack}
          autoHideDuration={4000}
          onClose={() => this.setState({ noMoreItemsSnack: false })}
        >
          <Alert
            onClose={() => this.setState({ noMoreItemsSnack: false })}
            severity="warning"
          >
            재고가 부족합니다!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ top: 110 }}
          open={this.state.getItemInCartSnack}
          autoHideDuration={10000}
          onClose={() => this.setState({ getItemInCartSnack: false })}
        >
          <Alert
            onClose={() => this.setState({ getItemInCartSnack: false })}
            action={
              <Button
                onClick={() => {
                  this.props.history.push("/cart");
                  setTimeout(() => {
                    this.props.routeChanges();
                  }, 10);
                }}
                size="small"
              >
                <span
                  style={{ fontSize: 13, color: "white", fontWeight: "bold" }}
                >
                  Go to 장바구니
                </span>
              </Button>
            }
            severity="success"
          >
            상품이 장바구니에 담겼습니다.
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

export default withRouter(MilkTeaBase);
