import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './components/Routes';
import { BrowserRouter, Route } from 'react-router-dom'
import * as firebase from 'firebase';
import 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATA_BASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

class App extends Component {

  componentWillMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
