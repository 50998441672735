import React, { Component, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MainLogo from "../MainLogo.png";
import NavBar from "./NavBar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Modal from "@material-ui/core/Modal";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  Link,
} from "react-router-dom";

import { Beforeunload } from "react-beforeunload";
import shortid from "shortid";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import newProduct from "../newProduct.png";
import sconeClass from "../sconeClassDetail.jpg";
import eggSconeClass from "../eggSconeClassDetail.jpg";

import classButtonImage_scones from "../classButtonImage_scones.jpg";
import classButtonImage_eggs from "../classButtonImage_eggs.jpg";

import Sheet from "react-modal-sheet";

import { withRouter } from "react-router-dom";

import { isMobileOnly, isIE, isEdge, isSafari } from "react-device-detect";
import { Prompt } from "react-router";

import Select from "react-select";

import { Image, Col, Row, Container } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!

import moment from "moment";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCartOutlined";
import LocalShippingIcon from "@material-ui/icons/LocalShippingOutlined";
import LocalMallIcon from "@material-ui/icons/LocalMallOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faTruckMoving,
  faCheck,
  faWarehouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ReactStars from "react-star-ratings";
import Slider from "react-slick";

import Footer from "./Footer";

import chatIcon from "../coffeeIcon.jpg";
import plainSconeIcon from "../PlainScone.png";
import compoteAndCreamDetail from "../compoteAndCreamDetail.jpg";
import roundIcon from "../roundIcon.png";

import * as firebase from "firebase";

import { loadTossPayments } from "@tosspayments/sdk";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SconeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStart: false,
      isLoading: this.props.isLoading,
      userInfo: this.props.userInfo,
      milkTeaBase: null,

      items: null,
      users: null,

      reviews: [],
      showIndex: null,
      imageIndex: 0,

      selectedItems: {},
      noItemsSnack: false,

      selectedItemsTotalAmount: 0,
      selectedItemsTotalPrice: 0,

      noMoreItemsSnack: false,

      openMilkTeaBaseItems: false,
      getItemInCartSnack: false,
      waitMessage: "잠시만 기다려주세요...",
      classType: null,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ loadingStart: true });
    }, 100);
    setTimeout(() => {
      this.setState({ waitMessage: "내용을 불러오고 있습니다..." });
    }, 5000);

    setTimeout(() => {
      this.setState({ waitMessage: "거의 다 됐습니다!!!" });
    }, 10000);
  }

  componentDidMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo();
    }
    firebase
      .database()
      .ref(`users`)
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          let users = snapshot.val();
          this.setState({ users: users });
        }
      });
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading === false) {
      this.setState({ userInfo: nextProps.userInfo, isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
  }

  onChangeCurrencyFormat(value) {
    return value
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  onDateToTimeStamp(date) {
    let changedDate =
      date.substring(0, 4) +
      "-" +
      date.substring(4, 6) +
      "-" +
      date.substring(6, 8);
    let timestamp = new Date(changedDate).getTime();
    return timestamp;
  }

  onStringNumberToDate(str) {
    let stringNumber = str.toString();
    return (
      stringNumber.substring(0, 4) +
      "년 " +
      stringNumber.substring(4, 6) +
      "월 " +
      stringNumber.substring(6, 8) +
      "일"
    );
  }

  onGetLastWeek() {
    let today = new Date();
    let lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    return lastWeek;
  }

  onGetCurrentDateWithDay(day) {
    let today = new Date();
    let dd = day.toString().padStart(2, "0");
    let mm = (today.getMonth() + 1).toString().padStart(2, "0");
    let yyyy = today.getFullYear();
    let result = new Date(`${yyyy}/${mm}/${day}`);
    return result;
  }

  onChangePhoneNumberFormat(phone) {
    let value = "";
    if (phone) {
      if (phone.substr(0, 3) === "010") {
        if (phone.substr(7, 4)) {
          value =
            phone.substr(0, 3) +
            "-" +
            phone.substr(3, 4) +
            "-" +
            phone.substr(7, 4);
        } else if (phone.substr(3, 4)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 4);
        } else {
          value = phone.substr(0, 3);
        }
      } else {
        if (phone.substr(6, 4)) {
          value =
            phone.substr(0, 3) +
            "-" +
            phone.substr(3, 3) +
            "-" +
            phone.substr(6, 4);
        } else if (phone.substr(3, 3)) {
          value = phone.substr(0, 3) + "-" + phone.substr(3, 3);
        } else {
          value = phone.substr(0, 3);
        }
      }
    }

    return value;
  }

  onGetWeek(time) {
    let d = time;
    let date = d.getDate();
    let day = d.getDay();

    let weekOfMonth = Math.ceil((date + 6 - day) / 7);
    return weekOfMonth;
  }

  onGetMonth(time) {
    return time.getMonth();
  }

  onGetLastDayOfMonth() {
    const lastDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate();
    return lastDay;
  }

  onGetDay(date) {
    let day = "";
    if (new Date(this.onDateToTimeStamp(date.toString())).getDay() === 0) {
      day = "일";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 1
    ) {
      day = "월";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 2
    ) {
      day = "화";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 3
    ) {
      day = "수";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 4
    ) {
      day = "목";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 5
    ) {
      day = "금";
    } else if (
      new Date(this.onDateToTimeStamp(date.toString())).getDay() === 6
    ) {
      day = "토";
    }
    return day;
  }

  sortByOrder(array) {
    let returnValue = array;
    let sconeMenu = this.state.sconeMenu;
    returnValue.sort((a, b) => {
      return sconeMenu[a.id].order - sconeMenu[b.id].order;
    });
    return returnValue;
  }

  onCalculateDiscount(price, rate) {
    let totalPrice = price * (1 - rate);
    return Number(Math.floor(totalPrice / 10) * 10);
  }

  onCountSelectedItems(selectedItems) {
    if (Object.values(selectedItems).length !== 0) {
      let selectedItemsTotalAmount = 0;
      let selectedItemsTotalPrice = 0;
      Object.values(selectedItems).map((item, index) => {
        selectedItemsTotalAmount = selectedItemsTotalAmount + item.amount;
        if (item.discountedPrice) {
          selectedItemsTotalPrice =
            selectedItemsTotalPrice + item.discountedPrice * item.amount;
        } else {
          selectedItemsTotalPrice =
            selectedItemsTotalPrice + item.price * item.amount;
        }

        if (Object.values(selectedItems).length === index + 1) {
          this.setState({
            selectedItemsTotalPrice: selectedItemsTotalPrice,
            selectedItemsTotalAmount: selectedItemsTotalAmount,
          });
        }
      });
    } else {
      this.setState({
        selectedItemsTotalAmount: 0,
        selectedItemsTotalPrice: 0,
      });
    }
  }

  onGetTimeDetail(time) {
    let years = new Date(time).getFullYear();
    let months = new Date(time).getMonth() + 1;
    let days = new Date(time).getDate();
    let hours = new Date(time).getHours();
    let minutes = new Date(time).getMinutes();
    let seconds = new Date(time).getSeconds();

    let morningOrAfternoon = "오전";

    if (Number(hours) > 11) {
      morningOrAfternoon = "오후";
    }

    if (Number(hours) > 12) {
      hours = hours - 12;
    }

    if (hours === 0) {
      hours = 12;
    }

    if (Number(minutes) < 10) {
      minutes = "0" + minutes;
    }

    return `${years}.${months}.${days}`;
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 100,
      }),
    };

    if (false) {
      return (
        <div className="center-div direction-column-div">
          <Image style={{ width: 250 }} src={newProduct} />
          <span
            className="text-center"
            style={{ fontSize: 16, fontWeight: "bold", marginTop: 15 }}
          >
            새로운 상품을 준비 중입니다.
          </span>
        </div>
      );
    }

    if (this.state.isLoading) {
      return (
        <div
          className={
            this.state.loadingStart
              ? "center-div direction-column-div loading-animation1"
              : "center-div direction-column-div loading-animation2"
          }
        >
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Image src={roundIcon} style={{ width: 60, height: 60 }} fluid />
            <span
              className="text-center"
              style={{ fontSize: 11, marginTop: 5 }}
            >
              {this.state.waitMessage}
            </span>
          </Grid>
        </div>
      );
    }

    let settings = {
      className: "",
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: false,
    };

    if (!this.state.classType) {
      return (
        <Grid container justify="center" style={{ marginTop: 120 }}>
          <div
            className="direction-column-div"
            style={{
              marginTop: 10,
              marginBottom: 50,
              justifyContent: "center",
            }}
          >
            <a
              onClick={() => {
                this.setState({ classType: "sconeClass" });
              }}
            >
              <div className="class-page-button  direction-columnl-div">
                <Image
                  src={classButtonImage_scones}
                  className="width-100-image max-height-100"
                  fluid
                  style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
                />
                <p style={{ fontWeight: "bold" }}>스콘 7종 클래스</p>
              </div>
            </a>
            <a
              onClick={() => {
                this.setState({ classType: "eggSconeClass" });
              }}
            >
              <div className="class-page-button  direction-columnl-div">
                <Image
                  src={classButtonImage_eggs}
                  className="width-100-image max-height-100"
                  fluid
                  style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
                />
                <p style={{ fontWeight: "bold" }}>에그스콘 클래스</p>
              </div>
            </a>
          </div>
        </Grid>
      );
    }

    if (this.state.classType === "sconeClass") {
      return (
        <Grid
          className="main-view auto-width-100 no-selection"
          style={{ marginTop: 30 }}
        >
          <div className="max-height-100">
            <div style={{ position: "relative" }}>
              <Image
                className="auto-width-100 max-height-100"
                src={sconeClass}
                fluid
              />
            </div>
          </div>
          <Grid
            container
            justify="center"
            style={{
              width: "100%",
              height: 50,
              position: "fixed",
              top: 110,
              left: 0,
              backgroundColor: "white",
            }}
          >
            <div
              style={{ cursor: "pointer", width: 600 }}
              onClick={() => {
                this.setState({ classType: null });
              }}
            >
              <div>
                <ArrowBackIosIcon
                  style={{
                    color: "black",
                    fontSize: 16,
                    padding: 10,
                    marginLeft: 10,
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }

    if (this.state.classType === "eggSconeClass") {
      return (
        <Grid
          className="main-view auto-width-100 no-selection"
          style={{ marginTop: 30 }}
        >
          <div className="max-height-100">
            <div style={{ position: "relative" }}>
              <Image
                className="auto-width-100 max-height-100"
                src={eggSconeClass}
                fluid
              />
            </div>
          </div>
          <Grid
            container
            justify="center"
            style={{
              width: "100%",
              height: 50,
              position: "fixed",
              top: 110,
              left: 0,
              backgroundColor: "white",
            }}
          >
            <div
              style={{ cursor: "pointer", width: 600 }}
              onClick={() => {
                this.setState({ classType: null });
              }}
            >
              <div>
                <ArrowBackIosIcon
                  style={{
                    color: "black",
                    fontSize: 16,
                    padding: 10,
                    marginLeft: 10,
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
  }
}

export default withRouter(SconeClass);
